import React from 'react'
import { navigate } from 'gatsby-link'

import styles from './contactForm.module.css'

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

function ContactForm () {
  const [state, setState] = React.useState({})

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }

  return (
    <div className={styles.formBox}>
      <form
        id='contact'
        className={styles.form}
        name='contact'
        method="post"
        action="/thanks/"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={handleSubmit}
        >
        <input type="hidden" name="form-name" value="contact" />
        <p hidden>
          <label>
            Don’t fill this out: <input name="bot-field" onChange={handleChange} />
          </label>
        </p>
        <div className={styles.inputGroup}>
          <input type="text" className={styles.inputShort} name="firstName" placeholder="First Name" onChange={handleChange} />
          <input type="text" className={styles.inputShort} name="lastName" placeholder="Last Name" onChange={handleChange} />
        </div>
        <input name="email" type="email" placeholder="Email" onChange={handleChange} />
        <input name="phone" type="text" placeholder="###-###-####" onChange={handleChange} />

        <textarea name="message" placeholder="Your message..." form="contact" onChange={handleChange} />
        <input type="submit" />
      </form>
    </div>
  )
}

// MerchPreviewGrid.defaultProps = {
//   title: '',
//   nodes: [],
//   browseMoreHref: ''
// }

export default ContactForm
